<template>
    <div>
        <div v-if="materials.length" class="bg-table auto-scroll-vertical">
            <table class="edit-table text-break">
                <tr v-for="material in materials">
                    <td class="w-25">
                        <div class="material-title">
                            <div class="material-title__img">
                                <img :src="material.icon ? material.icon.icon : defaultIcon" alt="">
                            </div>
                            <div class="material-title__name">{{material.title}}</div>
                        </div>
                    </td>
                    <td class="text-break">{{ material.url }}</td>
                </tr>
            </table>
        </div>
        <b-alert :show="!materials.length" variant="info">Нет доступных материлов</b-alert>
    </div>
</template>

<script>
    export default {
        name: "MaterialList",
        props: {
            materials: {
                type: Array,
                required: true
            }
        },
        data(){
            return{
                defaultIcon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEhQTFRFAAAALGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4ssB2BgAAABh0Uk5TABqw+v/ZpECm2HMN1D2JaZdxMJzFDMaAdt8bswAAAHhJREFUeJztkkkOgCAMRQuCoDjgyP1vKoMhERqJK134FqRpX7sgH+AjEFqxjIqSKNB87KBRQPb9jSjgc8Z+4VbgdUEQIHGhCZstgOpQoYfBvtI1BCaMttCMh86UC9pXcj5bSyqsG1xQeyIYSDCPPqoYuWJoi7F/mQNm8gjj30+HFQAAAABJRU5ErkJggg=='
            }
        }
    }
</script>

<style lang="scss" scoped>
    .auto-scroll-vertical{
        max-height: 365px;
    }
    .edit-table{
        tr{
            td:first-child{
                width: 35%;
            }
        }
    }
    .material-title{
        display: flex;
        align-items: center;
        min-height: 48px;
        &__img{
            width: 48px;
            height: 48px;
            flex: none;
            border-radius: 50%;
            background: #fff;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                max-width: 100%;
            }
        }
        &__name{
            flex: 1;
            padding-left: 15px;
        }
    }
</style>