<template>
    <span class="hourglass">
        <b-icon icon="hourglass-split"></b-icon>
    </span>
</template>

<script>
    export default {
        name: "DraftIcon",
        props: {
            draft: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .hourglass{
        color: #969696;
        margin: 0 10px 0 5px;
    }
</style>