export default{
    methods: {
        parseDateTime(date) {
            const date_ob = new Date(date);
            const year = date_ob.getFullYear();
            const month = `${date_ob.getMonth() + 1 < 10 ? '0' : ''}${date_ob.getMonth() + 1}`;
            const day = ("0" + date_ob.getDate()).slice(-2);
            const hours = date_ob.getHours();
            const minutes = `${date_ob.getMinutes() < 10 ? '0' : ''}${date_ob.getMinutes()}`;

            return `${day}.${month}.${year}, ${hours}:${minutes}`;
        }
    }
}