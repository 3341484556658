<template>
    <div class="dashboard">
        <router-link :to="{ name: 'forums' }" class="back-btn mb-3">
            <b-icon icon="chevron-left"></b-icon>
            <span>к списку событий</span>
        </router-link>

        <div class="wrapper">
            <div v-if="forum">
                <div class="forum-name">
                    <div class="h1">{{forum.title}}</div>
                    <div class="forum-attr">
                        <router-link :to="{ name: 'forum-edit', params: { forumId: getForumId } }" class="btn-crl">
                            <b-icon icon="pencil-fill"></b-icon>
                        </router-link>
                        <draft-icon class="ml-2" v-if="forum.draft" :draft="forum.draft"/>
                        <forum-status class="ml-2" :status="forum.status"/>
                        <button class="btn-crl ml-4" @click="handleDeleteForum(getForumId)">
                            <b-icon icon="trash-fill"></b-icon>
                        </button>
                    </div>
                </div>
                <div class="avatar-box">
                    <div class="avatar-box__img">
                        <img :src="forumAvatarSrc" class="uploaded-img" alt="">
                    </div>
                    <div class="avatar-box__desc">
                        <div class="forum-subtitle mb-4">
                            <div>
                                <div class="h5">{{forum.enTitle}}</div>
                                <div class="h6">{{forum.shortTitle}}</div>
                            </div>
                            <div>
                                <router-link :to="{name: 'forum-program'}" class="icon-btn">
                                    <b-icon icon="calendar-event"></b-icon>
                                    <span>программа ивента</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="row mb-2">
                                    <div class="col-xl-6 col-md-6">
                                        <div class="h6">Дата и время начала ивента:</div>
                                    </div>
                                    <div class="col-xl-6 col-md-6">
                                        <div class="h6 font-weight-bold">{{parseDateTime(forum.dateStart)}}</div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-xl-6 col-md-6">
                                        <div class="h6">Дата и время окончания ивента:</div>
                                    </div>
                                    <div class="col-xl-6 col-md-6">
                                        <div class="h6 font-weight-bold">
                                            <div v-if="forum.dateEnd">{{parseDateTime(forum.dateEnd)}}</div>
                                            <div v-else>-</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <div class="h6">Тип ивента:</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="h6 font-weight-bold">
                                            {{forum.top ? 'Форум' : 'Бизнес ивент'}}
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <div class="h6">Место проведения:</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="h6 font-weight-bold">{{forum.location}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="forum.price.length" class="underline pb-1 mb-4">
                    <div class="h6">Описание и цена билетов</div>
                    <div v-for="price in forum.price" class="row mb-2">
                        <div class="col-md-6 font-weight-bold">{{price.title}}</div>
                        <div class="col-md-6 font-weight-bold">{{price.amount}} грн.</div>
                        <div class="col-md-12 pre-line">{{price.description}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-10 col-md-12">
                        <div class="mb-4">
                            <div class="h6 mt-2 mb-2">Описание ивента</div>
                            <div class="description">{{forum.description}}</div>
                        </div>

                        <div class="mb-4">
                            <div class="h6 mb-3">Сцены ивента</div>
                            <scene-list :rooms="forum.rooms ? forum.rooms : []"/>
                        </div>

                        <div class="mb-4">
                            <div class="h6 mb-3">Спикеры ивента</div>
                            <speaker-list :speakers="forum.speakers"/>
                        </div>

                        <div class="mb-4">
                            <div class="h6 mb-3">Материалы ивента</div>
                            <material-list :materials="forum.materials"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="isForumData" class="text-center">
                <b-spinner></b-spinner>
            </div>
            <div v-if="!isForumData">Ивента не существует</div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import SceneList from '@/components/details/SceneList'
    import SpeakerList from '@/components/details/SpeakerList'
    import DraftIcon from '@/components/forum/DraftIcon'
    import ForumStatus from '@/components/forum/ForumStatus'

    import defaultImage from './images/default-image.svg'
    import MaterialList from "@/components/details/MaterialList";
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import parseDateTime from '@/mixins/parseDateTime'

    export default {
        name: "forum-details",
        components: {
            MaterialList,
            SceneList,
            SpeakerList,
            DraftIcon,
            ForumStatus
        },
        mixins: [parseDateTime],
        computed: {
            ...mapGetters([
                'forumByID'
            ]),
            getForumId(){
                return this.$route.params.forumId
            },
            forumAvatarSrc(){
                return this.forum.imagePath ? this.forum.imagePath : defaultImage
            },
        },
        methods:{
            ...mapActions([
                'getForumById',
                'deleteForum'
            ]),
            handleDeleteForum(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить ивент?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteForum(id).then(() => {
                            this.$router.push({ name: 'forums', replace: true })
                        })
                            .catch(error => {
                                errorsAlertApi(error);
                            })
                    }
                })
            }
        },
        mounted(){
            this.getForumById(this.getForumId).then(() => {
                this.forum = this.forumByID(this.getForumId);
            })
                .catch(() => {
                    this.isForumData = false;
                })
        },
        data(){
            return {
                forum: null,
                isForumData: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .forum-name{
        display: flex;
        justify-content: space-between;
    }
    .forum-subtitle{
        display: flex;
        justify-content: space-between;
    }
    .h6{
        color: #4b4b55;
    }
    .description{
        font-size: 14px;
        color: #232323;
    }
    .material-list{
        .icon-btn{
            margin: 0 40px 5px 0;
        }
    }
    .pre-line{
        white-space: pre-line;
    }
</style>