<template>
    <span class="forum-status" :class="status.toLowerCase()">
        {{parseStatus(status)}}
    </span>
</template>

<script>
    export default {
        name: "ForumStatus",
        props: {
            status: {
                type: String,
                required: true
            }
        },
        methods: {
            parseStatus(status){
                switch (status) {
                    case 'ACTIVE':
                        return 'в процессе';

                    case 'COMING':
                        return 'предстоящее';

                    default:
                        return 'прошедшее'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .forum-status{
        padding: 4px 8px;
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        background: #f15959;
        &.active{
            background: #5cb82c;
        }
        &.coming{
            background: #e4c03f;
        }
    }
</style>