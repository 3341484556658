<template>
    <div>
        <div v-if="rooms.length" class="bg-table">
            <table class="edit-table">
                <tr v-for="room in rooms">
                    <td>{{room.split(',')[0]}}</td>
                    <td>{{room.split(',')[1]}}</td>
                </tr>
            </table>
        </div>
        <b-alert :show="!rooms.length" variant="info">Нет доступных сцен</b-alert>
    </div>
</template>

<script>
    export default {
        name: "SceneList",
        props: {
            rooms: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>